import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
	faCheckCircle,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import  SynapsClient  from '@synaps-io/verify.js'
import AuthService from '@/services/auth'
import { AuthContext, AuthContextProvider } from '@/context/auth'

export default function DashboardPage() {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)

	function InitSynaps() {
		const Synaps = new SynapsClient(loginState.auth.session_id, loginState.auth.account_type.toLowerCase())
		Synaps.init({
		  type: 'embed',
		  colors: {
			primary: 'D4AC4A',
			secondary: 'FFFFFF'
		  }
		})
	  }

	  useEffect(async () => {
		  if (ready === true) {
			InitSynaps()
		  }
	  }, [ready])

	  useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
			.then((data) => {
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: data.email,
							firstname: data.firstname,
							lastname: data.lastname,
							profile_pic: data.profile_pic,
						},
						iam: data.iam,
						authenticated: true,
					},
				})

				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: data.session,
							account_type: data.session_type,
						},
					},
				})

				console.log('data')
				console.log(data)

				setReady(true)
			})
			.catch((err) => {
				console.log(err)
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: '',
							firstname: '',
							lastname: '',
							profile_pic: '',
						},
						iam: [],
						authenticated: false,
					},
				})
				setReady(true)
			})
		return () => { }
		} else {
			setReady(true)
		}
		return () => {}
	}, [])

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='md:flex mx-auto md:justify-between mt-3'>
							<div className='mt-5 mx-auto md:w-full bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-1/2'>
								<div className='synaps-modal' id="synaps-embed"></div>
							</div>
							<div className="mt-5 md:w-1/2 mr-5 ml-5 mx-auto md:w-full">
								<div className='bg-white rounded-xl p-6 shadow'>
								<h3 className="text-2xl text-primary-800 mb-1 font-bold">Start your verification process</h3>
									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Complete each verification step until each status shows <span className="text-green-500">Verified</span> or <span className="text-orange-500">Pending</span>
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Wait for Synaps to verify your data. The time needed for verification varies, depending on the project size and verification requirements.
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											You will receive an e-mail notification if any document(s) are <span className="text-red-500">Rejected</span>.
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Once every step has been <span className="text-green-500">Verified</span>, you receive an e-mail notifying that we have accepted your application.
										</div>
									</div>
									
								</div>
								
							</div>
						</div>
					</div>
					<Footer></Footer>
				</div>
			</div>
			

		</div>
	)
}
